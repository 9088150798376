import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import authService from "./authService";
import { setLocalStorageItem, getLocalStorageItem } from "../../utils/localStorageUtils";

const initialState = {
  user: '',
  isSuccess: false,
  isError: false,
  isLoading: false,
  message: '',
};

export const register = createAsyncThunk(
  'auth/registerTherapist',
  async (therapistData, thunkAPI) => {
    try {
      const therapistRegistered = await authService.register(therapistData);
      toast.success(therapistRegistered.message);
      return therapistRegistered;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      toast.error(e?.response?.data?.message || 'Unable to register therapist at the moment');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const therapistLogin = createAsyncThunk(
  'auth/loginTherapist',
  async (therapistData, thunkAPI) => {
    try {
      const therapist = await authService.therapistLogin(therapistData);
      // toast.success(therapist.message);
      return therapist;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      toast.error(e?.response?.data?.message || 'Unable to login at the moment');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updatePasswordOtpSubmission = createAsyncThunk(
  'auth/verifyResetPasswordOtpforTherapist',
  async (therapistData, thunkAPI) => {
    try {
      const therapist = await authService.updatePasswordOtpSubmission(therapistData);
      toast.success(therapist.message);
      return therapist;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      toast.error(e?.response?.data?.message || 'Unable to verify OTP at the moment');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const logoutTherapist = createAsyncThunk('auth/logoutTherapist', async (thunkAPI) => {
  try {
    await authService.logoutTherapist();
    toast.success('Logged Out Successfully');
  } catch (e) {
    const message = e?.toString();
    toast.error('Unable to logout at the moment');
    return thunkAPI.rejectWithValue(message);
  }
});

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    resetAuth: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(register.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.createdTherapist;
      })
      .addCase(register.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
        state.user = null;
      })
      .addCase(therapistLogin.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(therapistLogin.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.currentTherapist;
      })
      .addCase(therapistLogin.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
        state.user = null;
      })
      .addCase(updatePasswordOtpSubmission.pending, (state) => {
        state.isLoading = true;
        state.isSuccess = false;
      })
      .addCase(updatePasswordOtpSubmission.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.user = action.payload.currentTherapist;
      })
      .addCase(updatePasswordOtpSubmission.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload.message;
        state.user = null;
      })
      .addCase(logoutTherapist.fulfilled, (state) => {
        state.user = null;
        state.isSuccess = true;
        state.message = 'Logged out';
      });
  },
});

export const { resetAuth } = authSlice.actions;
export default authSlice.reducer;