import axios from "axios";
import { removeLocalStorageItem, setLocalStorageItem } from "../../utils/localStorageUtils";

const URL = `${process.env.REACT_APP_API_URL}/api/therapist`;
const register = async (therapistData) => {
  const response = await axios.post(URL, therapistData);
  if (response.data) {
    setLocalStorageItem('token', response.data.access_token);
  }
  return response.data;
};

const therapistLogin = async (therapistData) => {
  const response = await axios.post(`${URL}/login`, therapistData);
  if (response.data) {
    setLocalStorageItem('token', response.data.access_token);
  }
  return response.data;
};

const updatePasswordOtpSubmission = async (therapistData) => {
  const response = await axios.post(`${URL}/verify-reset-password-otp`, therapistData);
  if (response.data) {
    setLocalStorageItem('token', response.data.access_token);
  }
  return response.data;
};

const logoutTherapist = async () => {
  removeLocalStorageItem('token');
};

const authService = {
  register,
  therapistLogin,
  updatePasswordOtpSubmission,
  logoutTherapist,
};
export default authService;