import axios from "axios";
import { getLocalStorageItem, removeLocalStorageItem, setLocalStorageItem } from "../../utils/localStorageUtils";
import instance from "../../utils/axiosConfig";

const URL = `${process.env.REACT_APP_API_URL}/api/client`;

const registerClient = async (clientData) => {
  const response = await axios.post(`${URL}/`, clientData);
  if (response.data) {
    setLocalStorageItem('client', response.data.createdClient);
    setLocalStorageItem('token', response.data.access_token);
  }
  return response.data;
};

const authClientService = {
  registerClient,
};
export default authClientService;