import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import authClientService from "./authClientService";
import { setLocalStorageItem, getLocalStorageItem } from "../../utils/localStorageUtils";

const client = getLocalStorageItem('client');
const initialState = {
  authClient: client || '',
  isSuccess: false,
  isError: false,
  isLoading: false,
  message: '',
};

export const registerClient = createAsyncThunk(
  'auth/registerClient',
  async (clientData, thunkAPI) => {
    try {
      const client = await authClientService.registerClient(clientData);
      toast.success(client.message);
      return client;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      toast.error(e?.response?.data?.message || 'Could not register client at the moment');
      return thunkAPI.rejectWithValue(message);
    }
  }
);



export const authClientSlice = createSlice({
  name: 'authClient',
  initialState,
  reducers: {
    reset: (state) => {
      return initialState;  
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(registerClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(registerClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.authClient = action.payload.createdClient;
      })
      .addCase(registerClient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false
        state.message = action.payload.message;
        state.authClient = null;
      });
  }
});

export const { reset } = authClientSlice.actions;
export default authClientSlice.reducer;