import intakeFormService from "./intakeFormService";
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";

const initialState = {
  intakeForm: '',
  intakeForms: [],
  isSuccess: false,
  isError: false,
  isLoading: false,
  message: '',
};

export const createIntakeForm = createAsyncThunk(
  'intakeForm/createIntakeForm',
  async (formData, thunkAPI) => {
    try {
      const intakeForm = await intakeFormService.createIntakeForm(formData);
      toast.success(intakeForm.message);
      return intakeForm;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      toast.error(e?.response?.data?.message || 'Unable to create intake form');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getIntakeFormsOfTherapist = createAsyncThunk(
  'intakeForm/getIntakeFormByTherapistId',
  async (therapistId, thunkAPI) => {
    try {
      const intakeForm = await intakeFormService.getIntakeFormsOfTherapist(therapistId);
      return intakeForm;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      toast.error(e?.response?.data?.message || 'Unable to retrieve intake forms for therapist');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const getIntakeForm = createAsyncThunk(
  'intakeForm/getIntakeForm',
  async (intakeFormId, thunkAPI) => {
    try {
      const intakeForm = await intakeFormService.getIntakeForm(intakeFormId);
      toast.success(intakeForm.message);
      return intakeForm;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      toast.error(e?.response?.data?.message || 'Unable to retrieve intake forms for therapist');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateIntakeForm = createAsyncThunk(
  'intakeForm/updateIntakeForm',
  async (data, thunkAPI) => {
    try {
      const intakeForm = await intakeFormService.updateIntakeForm(data);
      toast.success(intakeForm.message);
      return intakeForm;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      toast.error(e?.response?.data?.message || 'Unable to update intake form');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteIntakeForm = createAsyncThunk(
  'intakeForm/deleteIntakeForm',
  async (intakeFormId, thunkAPI) => {
    try {
      const intakeForm = await intakeFormService.deleteIntakeForm(intakeFormId);
      toast.success(intakeForm.message);
      return intakeForm;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      toast.error(e?.response?.data?.message || 'Unable to delete intake form');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const intakeFormSlice = createSlice({
  name: 'intakeForm',
  initialState,
  reducers: {
    reset: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(createIntakeForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(createIntakeForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.intakeForm = action.payload.intakeForm;
        state.intakeForms.push(action.payload.intakeForm);
      })
      .addCase(createIntakeForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.intakeForm = null;
      })
      .addCase(updateIntakeForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateIntakeForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.intakeForm = action.payload.intakeForm;
        const index = state.intakeForms.findIndex(
          (form) => form?._id === action.payload.intakeForm?._id
        );
        if (index !== -1) {
          state.intakeForms[index] = action.payload.intakeForm;
        } else {
          state.intakeForms.push(action.payload.intakeForm);
        }
      })
      .addCase(updateIntakeForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.isSuccess = false;
        state.message = action.payload.message;
      })
      .addCase(getIntakeForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIntakeForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.intakeForm = action.payload.intakeForm;
      })
      .addCase(getIntakeForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
        state.intakeForm = null;
      })
      .addCase(getIntakeFormsOfTherapist.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getIntakeFormsOfTherapist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.intakeForms = action.payload.intakeForms;
        state.intakeForm = null;
      })
      .addCase(getIntakeFormsOfTherapist.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.intakeForms = null;
      })
      .addCase(deleteIntakeForm.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteIntakeForm.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.intakeForm = null;
        state.message = 'Intake Form Deleted'
        state.intakeForms = state.intakeForms.filter(
          (form) => form?._id !== action.payload.intakeForm?._id // Use deletedIntakeForm._id here
        );
      })
      .addCase(deleteIntakeForm.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
        state.intakeForm = null;
      });
  }
});

export const { reset } = intakeFormSlice.actions;
export default intakeFormSlice.reducer;