import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import therapistService from "./therapistService";
import { setLocalStorageItem, getLocalStorageItem } from "../../utils/localStorageUtils";

const initialState = {
  therapist: '',
  isSuccess: false,
  isError: false,
  isLoading: false,
  message: '',
};

export const getTherapist = createAsyncThunk(
  'therapist/getTherapist',
  async (therapistId, thunkAPI) => {
    try {
      const therapist = await therapistService.getTherapist(therapistId);
      return therapist;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendOtp = createAsyncThunk(
  'therapist/sendOtp',
  async (therapistId, thunkAPI) => {
    try {
      const sentEmail = await therapistService.sendOtp(therapistId);
      toast.success(sentEmail.message);
      return sentEmail;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      toast.error(e?.response?.data?.message || 'Unable to send OTP at the moment');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifyRegistrationStatus = createAsyncThunk(
  'therapist/verifyTherapist',
  async (therapistData, thunkAPI) => {
    try {
      const verifyTherapist = await therapistService.verifyRegistrationStatus(therapistData);
      toast.success(verifyTherapist.message);
      return verifyTherapist;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      toast.error(e?.response?.data?.message || 'Unable to verify therapist at the moment');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const completeTherapistProfile = createAsyncThunk(
  'therapist/completeTherapistProfile',
  async (therapistData, thunkAPI) => {
    try {
      const updatedTherapist = await therapistService.completeTherapistProfile(therapistData);
      toast.success(updatedTherapist.message);
      return updatedTherapist;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      toast.error(e?.response?.data?.message || 'Unable to complete profile at the moment');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const forgotPassword = createAsyncThunk(
  'therapist/forgotPasswordTherapist',
  async (therapistData, thunkAPI) => {
    try {
      const therapist = await therapistService.forgotPassword(therapistData);
      toast.success(therapist.message);
      return therapist;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      toast.error(e?.response?.data?.message || 'Unable to send password recovery OTP');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const resetPassword = createAsyncThunk(
  'therapist/resetPassword',
  async (therapistData, thunkAPI) => {
    try {
      const therapist = await therapistService.resetPassword(therapistData);
      toast.success(therapist.message);
      return therapist;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e?.toString();
      toast.error(e?.response?.data?.message || 'Unable to reset password at the moment');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const updateProfilePicture = createAsyncThunk(
  'therapist/updateProfilePicture',
  async (therapistData, thunkAPI) => {
    try {
      const therapist = await therapistService.updateProfilePicture(therapistData);
      toast.success(therapist.message);
      return therapist;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e?.toString();
      toast.error(e?.response?.data?.message || 'Unable to update profile picture at the moment');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const changePassword = createAsyncThunk(
  'therapist/changeTherapistPassword',
  async (therapistData, thunkAPI) => {
    try {
      const therapist = await therapistService.changePassword(therapistData);
      toast.success(therapist.message);
      return therapist;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e?.toString();
      toast.error(e?.response?.data?.message || 'Unable to change password at the moment');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deactivateTherapist = createAsyncThunk(
  'therapist/deactivateTherapist',
  async (therapistData, thunkAPI) => {
    try {
      const therapist = await therapistService.deactivateTherapist(therapistData);
      toast.success(therapist.message);
      return therapist;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e?.toString();
      toast.error(e?.response?.data?.message || 'Unable to deactivate your account at the moment');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const deleteTherapist = createAsyncThunk(
  'therapist/deleteTherapist',
  async (therapistId, thunkAPI) => {
    try {
      const therapist = await therapistService.deleteTherapist(therapistId);
      toast.success(therapist.message);
      return therapist;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e?.toString();
      toast.error(e?.response?.data?.message || 'Unable to delete your account at the moment');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const setAvailability = createAsyncThunk(
  'therapist/setAvailability',
  async (therapistData, thunkAPI) => {
    try {
      const therapist = await therapistService.setAvailability(therapistData);
      toast.success(therapist.message);
      return therapist;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      toast.error(e?.response?.data?.message || 'Unable to set availability at the moment');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const therapistSlice = createSlice({
  name: 'therapist',
  initialState,
  reducers: {
    reset: (state) => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getTherapist.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getTherapist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.therapist = action.payload.therapist;
      })
      .addCase(getTherapist.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.therapist = null;
      })
      .addCase(sendOtp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        // state.therapist = null;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        // state.therapist = null;
      })
      .addCase(verifyRegistrationStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyRegistrationStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.therapist = action.payload.therapist;
        state.message = action.payload.message;
      })
      .addCase(verifyRegistrationStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
        // state.therapist = null;
      })
      .addCase(completeTherapistProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(completeTherapistProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.therapist = action.payload.updatedTherapist;
        state.message = action.payload.message
      })
      .addCase(completeTherapistProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.therapist = null;
      })
      .addCase(forgotPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(forgotPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.therapist = action.payload.therapist;
      })
      .addCase(forgotPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.therapist = null;
      })
      .addCase(resetPassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(resetPassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.therapist = action.payload.therapist;
      })
      .addCase(resetPassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.therapist = null;
      })
      .addCase(updateProfilePicture.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(updateProfilePicture.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.therapist = action.payload.therapist;
      })
      .addCase(updateProfilePicture.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
      })
      .addCase(changePassword.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(changePassword.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.therapist = action.payload.therapist;
      })
      .addCase(changePassword.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
      })
      .addCase(deactivateTherapist.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deactivateTherapist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.therapist = action.payload.therapist;
      })
      .addCase(deactivateTherapist.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
        state.therapist = null;
      })
      .addCase(deleteTherapist.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteTherapist.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.therapist = null;
        state.message = 'Account Deleted'
      })
      .addCase(deleteTherapist.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
        state.therapist = null;
      })
      .addCase(setAvailability.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(setAvailability.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.therapist = action.payload.therapist;
      })
      .addCase(setAvailability.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload.message;
        state.therapist = null;
      });
  }
});

export const { reset } = therapistSlice.actions;
export default therapistSlice.reducer;