import axios from 'axios';
import { getLocalStorageItem } from './localStorageUtils';

const instance = axios.create();
instance.interceptors.request.use(
  (config) => {
    const token = getLocalStorageItem('token');
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;