import axios from "axios";

const URL = `${process.env.REACT_APP_API_URL}/api/client`;

const getClient = async (clientId) => {
  const response = await axios.get(`${URL}/${clientId}`);
  return response.data;
};

const sendOtp = async (clientId) => {
  const response = await axios.patch(`${URL}/otp/${clientId}`);
  return response.data;
};

const verifyClientRegistrationStatus = async (clientData) => {
  const { id, otp } = clientData;
  const response = await axios.patch(`${URL}/registrationStatus/${id}`, { otp });
  return response.data;
};

const completeClientProfile = async (clientData) => {
  const { id, client } = clientData;
  const response = await axios.put(`${URL}/${id}`, client);
  return response.data;
};

const clientService = {
  getClient,
  sendOtp,
  verifyClientRegistrationStatus,
  completeClientProfile,
};
export default clientService;