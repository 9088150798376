import axios from "axios";
import instance from "../../utils/axiosConfig";

const URL = `${process.env.REACT_APP_API_URL}/api/intakeForm`;

const createIntakeForm = async (formData) => {
  const response = await instance.post(`${URL}/`, formData);
  return response.data;
};

const getIntakeFormsOfTherapist = async (therapistId) => {
  const response = await instance.get(`${URL}/therapist/${therapistId}`);
  return response.data;
};

const getIntakeForm = async (intakeFormId) => {
  const response = await instance.get(`${URL}/${intakeFormId}`);
  return response.data;
}

const updateIntakeForm = async (data) => {
  const { formData, id } = data;
  const response = await instance.put(`${URL}/${id}`, formData);
  return response.data;
};

const deleteIntakeForm = async (intakeFormId) => {
  const response = await instance.delete(`${URL}/${intakeFormId}`);
  return response.data;
};

const intakeFormService = {
  createIntakeForm,
  getIntakeFormsOfTherapist,
  getIntakeForm,
  updateIntakeForm,
  deleteIntakeForm,
};

export default intakeFormService;