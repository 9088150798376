import { configureStore } from '@reduxjs/toolkit';
import authReducer from '../features/auth/authSlice';
import therapistReducer from '../features/therapist/therapistSlice';
import clientReducer from '../features/client/clientSlice';
import authClientReducer from '../features/authClient/authClientSlice';
import intakeFormReducer from '../features/intakeForm/intakeFormSlice';

const store = configureStore(
  {
    reducer: {
      auth: authReducer,
      therapist: therapistReducer,
      client: clientReducer,
      authClient: authClientReducer,
      intakeForm: intakeFormReducer,
    },
  },
  window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__()
);

export default store;