import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import clientService from "./clientService";

// const client = getLocalStorageItem('client');
const initialState = {
  client: '',
  isSuccess: '',
  isError: '',
  isLoading: '',
  message: '',
};

export const getClient = createAsyncThunk(
  'cliet/getClient',
  async (clientId, thunkAPI) => {
    try {
      const client = await clientService.getClient(clientId);
      return client;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const sendOtp = createAsyncThunk(
  'authClient/sendOtp',
  async (clientId, thunkAPI) => {
    try {
      const sentEmail = await clientService.sendOtp(clientId);
      toast.success(sentEmail.message);
      return sentEmail;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      toast.error(e?.response?.data?.message || 'Could not send OTP at the moment');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const verifyClientRegistrationStatus = createAsyncThunk(
  'auth/verifyClient',
  async (clientData, thunkAPI) => {
    try {
      const verifyClient = await clientService.verifyClientRegistrationStatus(clientData);
      toast.success(verifyClient.message);
      return verifyClient;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      toast.error(e?.response?.data?.message || 'Unable to verify your account at the moment');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const completeClientProfile = createAsyncThunk(
  'auth/completeClienttProfile',
  async (clientData, thunkAPI) => {
    try {
      const updatedClient = await clientService.completeClientProfile(clientData);
      toast.success(updatedClient.message);
      return updatedClient;
    } catch (e) {
      const message = e?.response?.data?.message || e?.message || e.toString();
      toast.error(e?.response?.data?.message || 'Unable to complete profile at the moment');
      return thunkAPI.rejectWithValue(message);
    }
  }
);

export const clientSlice = createSlice({
  name: 'client',
  initialState,
  reducers: {
    reset: (state) => {
      state.isLoading = false;
      state.isError = false;
      state.isSuccess = false;
      state.message = '';
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getClient.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(getClient.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.client = action.payload.client;
      })
      .addCase(getClient.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.client = null;
      })
      .addCase(sendOtp.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(sendOtp.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.client = null;
      })
      .addCase(sendOtp.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.client = null;
      })
      .addCase(verifyClientRegistrationStatus.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(verifyClientRegistrationStatus.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.authClient = action.payload.client;
      })
      .addCase(verifyClientRegistrationStatus.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.authClient = null;
      })
      .addCase(completeClientProfile.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(completeClientProfile.fulfilled, (state, action) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.authClient = action.payload;
      })
      .addCase(completeClientProfile.rejected, (state, action) => {
        state.isLoading = false;
        state.isError = true;
        state.message = action.payload;
        state.authClient = null;
      });
  }
});

export const { reset } = clientSlice.actions;
export default clientSlice.reducer;