import axios from "axios";
import instance from "../../utils/axiosConfig";
import { removeLocalStorageItem } from "../../utils/localStorageUtils";

const URL = `${process.env.REACT_APP_API_URL}/api/therapist`;

const getTherapist = async (therapistId) => {
  const response = await axios.get(`${URL}/${therapistId}`);
  return response.data;
};

const sendOtp = async (therapistId) => {
  const response = await axios.patch(`${URL}/otp/${therapistId}`);
  return response.data;
};

const verifyRegistrationStatus = async (therapistData) => {
  const { id, otp } = therapistData;
  const response = await axios.patch(`${URL}/registrationStatus/${id}`, { otp });
  return response.data;
};

const completeTherapistProfile = async (therapistData) => {
  const { id, therapist } = therapistData;
  const response = await axios.put(`${URL}/${id}`, therapist);
  return response.data;
};

const forgotPassword = async (therapistData) => {
  const response = await axios.patch(`${URL}/forgotPassword`, therapistData);
  removeLocalStorageItem('user');
  removeLocalStorageItem('token');
  return response.data;
};

const resetPassword = async (therapistData) => {
  const response = await instance.patch(`${URL}/reset-password`, therapistData);
  return response.data;
};

const updateProfilePicture = async (therapistData) => {
  const response = await axios.put(`${URL}/update-profile-picture`, therapistData, 
    {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }
  );
  return response.data;
};

const changePassword = async (therapistData) => {
  const response = await instance.patch(`${URL}/change-password/${therapistData._id}`, therapistData);
  return response.data;
};

const deactivateTherapist = async (therapistId) => {
  const response = await instance.patch(`${URL}/deactivate-therapist/${therapistId}`);
  return response.data;
};

const deleteTherapist = async (therapistId) => {
  const response = await instance.delete(`${URL}/${therapistId}`);
  if (response.data) {
    removeLocalStorageItem('user');
    removeLocalStorageItem('token');
  }
  return response.data;
};

const setAvailability = async (therapistData) => {
  const { therapistId, availability } = therapistData;
  const response = await instance.put(`${URL}/${therapistId}/set-availability`, availability);
  return response.data;
};

const addIntakeForm = async (therapistData) => {
  const { therapistId } = therapistData;
  const response = await instance.post(`${URL}/${therapistId}/add-intake-form`, therapistData);
  return response.data;
};

const therapistService = {
  getTherapist,
  sendOtp,
  verifyRegistrationStatus,
  completeTherapistProfile,
  forgotPassword,
  resetPassword,
  updateProfilePicture,
  changePassword,
  deactivateTherapist,
  deleteTherapist,
  setAvailability,
  addIntakeForm,
};
export default therapistService;