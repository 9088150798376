import React from 'react';
import { Navigate } from 'react-router-dom';
import { getLocalStorageItem } from '../utils/localStorageUtils';
import { jwtDecode } from 'jwt-decode';

function Protected({ Component }) {
  const token = getLocalStorageItem('token');

  const isTokenExpired = (token) => {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Current time in seconds
      return decodedToken.exp < currentTime;
    } catch (error) {
      return true; // If there's an error decoding, consider it expired
    }
  };

  if (!token || isTokenExpired(token)) {
      return <Navigate to="/" />;
  }
  return <Component />;
}

export default Protected;
